<template>
    <a-layout>
        <a-layout-header style="background: #fff; padding: 0"/>
        <a-layout-content style="margin: 0 16px">
            <a-breadcrumb style="margin: 16px 0">
                <a-breadcrumb-item>首页</a-breadcrumb-item>
                <a-breadcrumb-item>分类列表</a-breadcrumb-item>
            </a-breadcrumb>
            <div class="toolsbar">
                <div style="float: left;margin-left: 20px;margin-top: 10px;">
                    <!--                    <a-button type="primary" @click="addadmin()">新建分类</a-button>-->
                    <a-button @click="addcategory()" type="primary">新建分类</a-button>
                    <!--                    <router-link to="/itemmanger">新建分类</router-link>-->

                    <a-button :loading="loading" @click="start" style="margin-left: 20px" type="primary">
                        刷新
                    </a-button>
                </div>
                <div class="serach_input">
                    <a-input-group compact>
                        <a-select defaultValue="分类名">
                            <a-select-option value="username">分类名</a-select-option>
                            <!--                    <a-select-option value="username">用户名</a-select-option>-->
                        </a-select>
                        <a-input-search @search="onSearch" defaultValue enterButton style="width: 64%"/>
                    </a-input-group>
                </div>
            </div>

            <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
                <!--                                {{data}}-->
                <a-table
                        :columns="columns"
                        :dataSource="Category"
                        v-if="Category.length>0"
                        :defaultExpandAllRows = true
                        :loading="loading"
                        bordered
                        childrenColumnName="child"
                        rowKey="id"
                >
<!--                    :expandedRowKeys=[1]-->

                    <template slot="model_id" slot-scope="text, index" style="text-align: center">

                        <span v-if="text == null">-</span>
                        <span v-if="text !== null">{{Categorymodel.model[text].name}}</span>
                    </template>
                    <template slot="content_model_id" slot-scope="text, index" style="text-align: center">
                        <span v-if="text == null">-</span>
                        <span v-if="text !== null">{{articlemodel.model[text].name}}</span>
                    </template>

                    <template slot="is_sale" slot-scope="text, index" style="text-align: center">
                        <a-tag :color="'green'" v-if="index.is_sale === 1"><span>正常</span></a-tag>
                        <a-tag :color="'red'" v-if="index.is_sale === 2">下架</a-tag>
                    </template>
                    <template slot="avatar" slot-scope="text, index" style="text-align: center">
<!--{{JSON.parse(index.model_info_json).backimg.length}}-->
                        <span v-if="index.model_info_json">
                            <span v-if="JSON.parse(index.model_info_json).backimg.length == 0">-</span>
                            <span v-if="JSON.parse(index.model_info_json).backimg.length > 0"><img slot="avatar" :src="server_url+JSON.parse(index.model_info_json).backimg" width="288" height="80"/></span>
                        </span>
                        <span v-if="!index.model_info_json">-</span>
                        </template>
                    <template slot="operation" slot-scope="text, index">
<!--                        {{text.id}}-->
                                                <span style="margin-right: 5px;" v-if="text.id !== 1">
                                                  <a @click="showModal(index)">编辑</a>
                                                </span>
                        <span  v-if="text.id !== 1">
                                                  <a @click="deleadmin(index)">剔除</a>
                                                </span>
                    </template>


                </a-table>
            </div>
        </a-layout-content>
        <a-layout-footer style="text-align: center"></a-layout-footer>
        <a-modal @ok="addok" title="新增分类" v-model="popadd" width="45%">
            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="所属分类">
<!--                {{add.p_id}}-->
                <a-tree-select
                        v-model="add.p_id"
                        style="width: 100%"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :tree-data="Category"
                        placeholder="Please select"
                        tree-default-expand-all
                        :replace-fields="replaceFields"
                >
                    <!--    <span v-if="key === '0-0-1'" slot="title" slot-scope="{ key, value }" style="color: #08c">-->
                    <!--      Child Node1 {{ value }}-->
                    <!--    </span>-->
                </a-tree-select>
<!--                <a-select @change="handleChange" placeholder="请选择所属分类" style="width: 160px" v-model="">-->
<!--                    -->
<!--                    <a-select-option :key="item.id" :value="item.id"-->
<!--                                     v-for="(item,index) in Category">-->
<!--                        {{item.title}}-->

<!--                        -->
<!--                    </a-select-option>-->
<!--                </a-select>-->
            </a-form-item>
            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="分类模板">
                <a-select @change="handleChangemodel_id" placeholder="请选择分类模板" style="width: 160px"
                          v-model="add.model_id">
                    <a-select-option :key="item.id" :value="item.id"
                                     v-for="(item,index) in Categorymodel.model">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="内容模板">
                <a-select @change="handleChangecontent_model_id" placeholder="请选择内容模板" style="width: 160px"
                          v-model="add.content_model_id">
                    <a-select-option :key="item.id" :value="item.id"
                                     v-for="(item,index) in articlemodel.model">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="分类名字">
                <a-input placeholder="请输入分类名字" v-model="add.title"/>
            </a-form-item>
            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="跳转链接地址">
                <a-input placeholder="请输入跳转链接地址" v-model="jumpurl"/>
            </a-form-item>
            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="排序">
                <a-input placeholder="排序" v-model="rank"/>
            </a-form-item>
            <a-form-item
                    help="建议尺寸：1440*440像素"
                    label="分类头部背景图"
                    :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }"
                    v-if="this.add.content_model_id !== 3"
            >
                <!-- 点击上传拍照/上传图片 -->
                <!--                {{cover_img}}-->

                <a-upload
                        :action="server_url+'api/admin/tools/saveFileCropper?uid='+uid+'&token='+token+'&save_path=/images&size=1440:440'"
                        :file-list="fileList"
                        @change="handleimagesChange"
                        @preview="handlePreview"
                        list-type="picture-card"
                >
                    <div v-if="fileList.length < 1">
                        <a-icon type="plus"/>
                        <div class="ant-upload-text">
                            点击上传
                        </div>
                    </div>
                </a-upload>

                <a-modal :footer="null" :visible="previewVisible" @cancel="handleCancel">
                    <img :src="previewImage" alt="" style="width: 100%"/>
                </a-modal>
                <!--                        <a-input id="image_groups" placeholder="请输入商品名字"/>-->
            </a-form-item>
        </a-modal>
        <a-modal @ok="editok" title="编辑分类" v-model="popinfos" width="45%">
            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="所属分类">
                <a-tree-select
                        v-model="edit.p_id"
                        style="width: 100%"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :tree-data="Category"
                        placeholder="Please select"
                        tree-default-expand-all
                        :replace-fields="replaceFields"
                >
                    <!--    <span v-if="key === '0-0-1'" slot="title" slot-scope="{ key, value }" style="color: #08c">-->
                    <!--      Child Node1 {{ value }}-->
                    <!--    </span>-->
                </a-tree-select>
<!--                <a-select @change="handleChange" placeholder="请选择所属分类" style="width: 160px" v-model="edit.p_id">-->
<!--                    <a-select-option :key="item.id" :value="item.id"-->
<!--                                     v-for="(item,index) in Category">-->
<!--                        {{item.title}}-->
<!--                    </a-select-option>-->
<!--                </a-select>-->
            </a-form-item>

            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="分类模板">
                <a-select @change="handleChangemodel_id" placeholder="请选择分类模板" style="width: 160px"
                          v-model="edit.model_id">
                    <a-select-option :key="item.id" :value="item.id"
                                     v-for="(item,index) in Categorymodel.model">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="内容模板">
                <a-select @change="handleChangecontent_model_id" placeholder="请选择内容模板" style="width: 160px"
                          v-model="edit.content_model_id">
                    <a-select-option :key="item.id" :value="item.id"
                                     v-for="(item,index) in articlemodel.model">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-form-item>
<!--            {{fileList}}-->
            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="分类名字">
                <a-input placeholder="请输入分类名字" v-model="edit.title"/>
            </a-form-item>
            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="跳转链接地址">
                <a-input placeholder="请输入跳转链接地址" v-model="jumpurl"/>
            </a-form-item>
            <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="排序">
                <a-input placeholder="排序" v-model="rank"/>
            </a-form-item>
            <a-form-item
                    help="建议尺寸：1440*440像素"
                    label="分类头部背景图"
                    :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }"
                    v-if="this.edit.content_model_id !== 3"
            >
                <!-- 点击上传拍照/上传图片 -->
                <!--                {{cover_img}}-->

                <a-upload
                        :action="server_url+'api/admin/tools/saveFileCropper?uid='+uid+'&token='+token+'&save_path=/images&size=1440:440'"
                        :file-list="fileList"
                        @change="handleimagesChange"
                        @preview="handlePreview"
                        list-type="picture-card"
                >
                    <div v-if="fileList.length < 1">
                        <a-icon type="plus"/>
                        <div class="ant-upload-text">
                            点击上传
                        </div>
                    </div>
                </a-upload>

                <a-modal :footer="null" :visible="previewVisible" @cancel="handleCancel">
                    <img :src="previewImage" alt="" style="width: 100%"/>
                </a-modal>
                <!--                        <a-input id="image_groups" placeholder="请输入商品名字"/>-->
            </a-form-item>
        </a-modal>

    </a-layout>

</template>

<script>
    import Config from "../config";

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const columns = [

        {
            title: "名字",
            dataIndex: "title",
            width: "auto",
            scopedSlots: {customRender: "title"}
        }
        ,        {
            title: "分类编号",
            dataIndex: "id",
            width: "5%",
            scopedSlots: {customRender: "id"}
        },{
            title: "分类",
            dataIndex: "rank",
            width: "5%",
            scopedSlots: {customRender: "rank"}
        }, {
            title: "头部背景图",
            dataIndex: "avatar",
            width: "auto",
            scopedSlots: {customRender: "avatar"}
        },{
            title: "分类模板",
            dataIndex: "model_id",
            width: "auto",
            scopedSlots: {customRender: "model_id"}
        }, {
            title: "文章模板",
            dataIndex: "content_model_id",
            width: "auto",
            scopedSlots: {customRender: "content_model_id"}
        },
        {
            title: '操作',
            width: "7%",
            key: 'operation',
            scopedSlots: {customRender: 'operation'}
        },
    ];


    import {Modal} from "ant-design-vue";
    import Storage from "../common/storage";
    import articlemodel from '../common/articlemodel.json'
    import Categorymodel from '../common/Categorymodel.json'

    // import moment from "moment";
    // import * as Util from "../common/util";

    export default {
        name: "Dashboard",
        components: {},
        data() {
            return {
                columns,
                articlemodel,
                Categorymodel,
                Category: [],
                fileList: [],
                previewVisible: false,
                previewImage: '',
                cover_img: '',
                jumpurl: '',
                rank: '',
                model_info_json: '',
                imglist800: [],
                imglist220: [],
                uid: Storage.uid,
                token: Storage.token,
                pagination: {
                    pageSize: 10
                },
                replaceFields: {
                    children: 'child',
                    key: 'id',
                    value: 'id',
                },
                server_url: Config.data.server_url,
                popinfos: false,
                popadd: false,
                search_text: "",
                loading: false,
                add: {
                    p_id: "",
                    model_id: "",
                    title: "",
                    content_model_id: "",
                    model_info_json: "",
                },
                edit: {
                    uid: "",
                    p_id: "",
                    model_id: "",
                    title: "",
                    content_model_id: "",
                    model_info_json: "",
                },
            };
        },
        created() {
            // 获取第一页内容
            this.doSearch();
        },
        methods: {
            gotoadditem() {
                window.vue.$router.push({path: "/additem",});
            },


            start() {
                this.loading = true;
                this.data = [];
                // ajax request after empty completing
                setTimeout(() => {
                    this.loading = false;
                    this.selectedRowKeys = [];
                }, 1000);
                this.doSearch(1, this.pagination.pageSize, "", "", "", "", "1");
            },

            async onSearch(text) {
                this.data = []
                this.search_text = text;
                this.doSearch(1, this.pagination.pageSize, text);
            },

            async onChange(pagination) {
                await this.doSearch(
                    this.search_text,
                    pagination.current - 1,
                    pagination.pageSize
                );
            },

            async onChanges(e) {
                console.log(e.target.value);
            },

            showModal(item) {
                console.log(item)
                // window.vue.$router.push({
                //     path: "/edititem?id=",
                //     query: {
                //         id: item.id,
                //     }
                // });
                this.fileList = [];
                this.fileList = [
                    {
                        uid: '-1',
                        name: name,
                        status: 'done',
                        url: this.server_url+JSON.parse(item.model_info_json).backimg.toString(),
                        thumbUrl: this.server_url+JSON.parse(item.model_info_json).backimg.toString(),
                    }
                ];
                this.cover_img = "";
                this.jumpurl = JSON.parse(item.model_info_json).jumpurl.toString();
                this.cover_img = JSON.parse(item.model_info_json).backimg.toString();
                this.edit.uid = item.id;
                this.edit.p_id = item.p_id;
                this.rank = item.rank;
                this.edit.model_id = item.model_id;
                this.edit.title = item.title;
                this.edit.content_model_id = item.content_model_id;
                this.edit.model_info_json = item.model_info_json;

                this.popinfos = true;
            },
            addcategory() {
                this.add.p_id = "";
                this.cover_img = "";
                this.jumpurl = "";
                this.rank = "";
                this.add.model_id = "";
                this.add.title = "";
                this.add.content_model_id = "";
                this.add.model_info_json = "";
                this.fileList = [];
                // this.add.group_id = "";
                this.popadd = true;
            },
            async editok() {

                let now = Date.now();
                let result = await this.$post("/api/admin/articleCategory/update", {
                    uid: Storage.uid,
                    token: Storage.token,
                    id: this.edit.uid,
                    save_value: {
                        p_id: this.edit.p_id,
                        title: this.edit.title,
                        model_id: this.edit.model_id,
                        content_model_id: this.edit.content_model_id,
                        type: this.edit.type,
                        rank: this.rank,
                        model_info_json: JSON.stringify({
                            backimg:this.cover_img,
                            jumpurl:this.jumpurl,
                        }),
                        t: now,
                    },

                });

                if (result.status === true) {
                    this.data = []
                    await this.doSearch();
                    Modal.info({
                        title: "更新成功"
                    });
                }
                this.popinfos = false;
            },
            handleChange(value) {
                console.log(`selected ${value}`);
                this.add.p_id = value;
            },
            handleChangemodel_id(value) {
                console.log(`selected ${value}`);
                this.add.model_id = value;
            },
            handleChangecontent_model_id(value) {
                console.log(`selected ${value}`);
                this.add.content_model_id = value;
            },
            async addok() {


                if (this.add.p_id == "") {
                    this.add.p_id = "1";
                }

                if (this.add.model_info_json == "") {
                    this.add.model_info_json = "1";
                }

                if (this.add.content_model_id == "") {
                    this.add.content_model_id = "0";
                }

                if (this.add.title == "") {
                    Modal.warning({
                        title: "请输入分类名称"
                    });
                    this.popinfos = true;
                    return
                }

                let now = Date.now();
                let result = await this.$post("/api/admin/articleCategory/create", {
                    uid: Storage.uid,
                    token: Storage.token,
                    save_value: {
                        p_id: this.add.p_id,
                        rank: this.rank,

                        model_id: this.add.model_id,
                        title: this.add.title,
                        content_model_id: this.add.content_model_id,
                        model_info_json: JSON.stringify({
                            backimg:this.cover_img,
                            jumpurl:this.jumpurl,
                        }),
                        t: now,
                    },
                });

                if (result.status === true) {
                    this.Category = []
                    await this.doSearch();
                    Modal.info({
                        title: "创建成功"
                    });
                }
                this.popadd = false;
            },
            async doSearch(pid) {
                this.loading = true;
                this.Category = [];
                console.log(Storage.uid)
                console.log(Storage.token)
                let now = Date.now();
                let result = await this.$get("/api/admin/articleCategory/list", {
                    params: {
                        token: Storage.token,
                        uid: Storage.uid,
                        pid: pid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.Category = result.data;
                    this.loading = false;
                }

                this.loading = false;
            },
            async deleadmin(item) {
                var that = this;
                console.log(item);
                Modal.confirm({
                    title: "警告！",
                    content: "是否确认剔除该分类?",

                    onOk: async function () {

                        let result = await that.$post("/api/admin/articleCategory/delete", {

                            uid: Storage.uid,
                            token: Storage.token,
                            id: item.id,
                            // notes: this.edit_pop.notes,
                            t: Date.now(),
                            // sign: Crypt.sign([
                            //     Storage.token,
                            //     this.edit_pop.id,
                            //     JSON.stringify(data),
                            //     this.edit_pop.notes,
                            //     this.$config.secret_key,
                            //     now
                            // ])
                        });
                        console.log(result);
                        if (result.status == true) {
                            console.log("准备弹窗！");
                            that.data = []
                            await that.doSearch(1, that.pagination.pageSize, "", "", "", "", "1");


                            Modal.info({
                                title: "剔除成功"
                            });
                        }
                        if (result.status === false) {
                            // that.data = []
                            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
                            Modal.info({
                                title: result.msg
                            });
                        }
                    }

                });


            },
            handleCancel() {
                this.previewVisible = false;
            },
            async handlePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            handleimagesChange(info) {
                this.cover_img="";
                // console.log(info);
                console.log(info.file.status);
                this.fileList = info.fileList;
                for (var i = 0; i < this.fileList.length; i++) {
                    if(info.file.status == "done" || info.file.status == "removed"){

                        this.cover_img = this.fileList[i].response.data[0];

                    }
                    // console.log(info.fileList[i]);
                    // console.log(info.fileList[i].response.data);
                }


            },
        }
    };
</script>
<style scoped>
    .editable-row-operations a {
        margin-right: 8px;
    }

    #components-layout-demo-custom-trigger .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    #components-layout-demo-custom-trigger .trigger:hover {
        color: #1890ff;
    }

    #components-layout-demo-custom-trigger .logo {
        height: 32px;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px;
    }

    .serach_input {
        width: 25vw;
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
        margin-right: 25px;
    }

    .pages {
        float: right;
        margin-top: 10px;
    }

    .toolsbar {
        display: block;
        width: 100%;
        height: 42px;
        background: #fff
    }
</style>
